<template>
  <div :class="`${isMobile ? 'h-100' : 'row w-100 h-100'}`">
    <div class="col-sm left-div" v-if="!isMobile" />
    <div :class="`${isMobile ? 'mt-10 mx-2' : 'col-sm d-flex flex-column justify-content-center'}`">
      <div :class="`${isMobile ? 'w-100' : 'w-75 mx-auto'} p-5 box-white center`">
        <span class="font-25 font-bold">{{ $t($route.name) }}</span>
        <VerifyEmailPhoneComponent></VerifyEmailPhoneComponent>
      </div>
      <TermsComponent></TermsComponent>
    </div>
  </div>
</template>

<style lang="scss">
  @import "@/assets/sass/pages/auth.scss";
</style>

<script>
import VerifyEmailPhoneComponent from "@/view/pages/auth/VerifyEmailPhoneComponent.vue";
import TermsComponent from "@/view/pages/auth/TermsComponent.vue";

export default {
  name: 'VerifyEmailPhone',
  components: {
    VerifyEmailPhoneComponent,
    TermsComponent
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  }
};
</script>